import React, { Component, Suspense, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Body, Navbar, Card, LoadingCover, Loading, Button } from '../Layout';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import '../index.css';
import { Link } from 'react-router-dom';

function Input({
  label,
  value,
  id,
  onChange,
}: {
  label: string;
  value: string;
  id: string;
  onChange: (val: string) => void;
}) {
  return (
    <div className="mb-4">
      <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="username">
        {label}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
        id={id}
        type="text"
        placeholder={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

function SensorEdit({
  sensor,
  onChange,
  remove,
  unpair,
  unpairing,
}: {
  sensor: any;
  onChange: (name: string, value: string) => void;
  unpair: (e: any) => void;
  remove: (e: any) => void;
  unpairing: boolean;
}) {
  const { loading, data } = useQuery(
    gql`
      {
        readingTypes
      }
    `
  );

  if (loading) return <></>;

  return (
    <div>
      <div className="flex justify-between">
        <h4>{sensor.id}</h4>
        <Button onClick={unpair}>{unpairing ? 'Unpairing...' : 'Unpair'}</Button>
        <Button onClick={remove}>Remove</Button>
      </div>
      <Input id="id" label="ID" value={sensor.id} onChange={(v) => onChange('id', v)} />
      <Input id="name" label="Name" value={sensor.name} onChange={(v) => onChange('name', v)} />
      <div className="mb-4">
        <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="username">
          Type
        </label>
        <select
          className="block appearance-none w-full bg-white border border-gray text-grey-darker py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray"
          value={sensor.type}
          onChange={(e) => onChange('type', e.target.value as string)}
        >
          <option value="undefined">Select Type</option>
          {data.readingTypes.map((t: any) => (
            <option value={t}>{t}</option>
          ))}
        </select>
      </div>
      <Input
        id="comments"
        label="Comments"
        value={sensor.comments}
        onChange={(v) => onChange('comments', v)}
      />
    </div>
  );
}

function DeviceEdit({ device }: { device: any }) {
  const [id, setId] = useState(device.id);
  const [name, setName] = useState(device.name);
  const [latitude, setLatitude] = useState(device.latitude);
  const [longitude, setLongitude] = useState(device.longitude);

  const [sensors, setSensors] = useState(
    (device.sensors as any[]).sort(
      (a: any, b: any) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
    )
  );

  const [unpairing, setUnpairing] = useState(false);

  const query = gql`
    mutation($device: DeviceInput!) {
      upsertDevice(data: $device) {
        id
      }
    }
  `;
  const [upsertDevice, { data }] = useMutation(query);

  const history = useHistory();

  const onSensorChange = (idx: number, prop: string, value: string) => {
    let s = [...sensors];
    s[idx][prop] = value;
    setSensors(s);
  };

  const addSensor = (e: any) => {
    e.preventDefault();
    let s = [...sensors, {}];
    setSensors(s);
  };
  const unpair = (idx: number) => {
    setUnpairing(true);
    const API = process.env.REACT_APP_API_URL;
    fetch(`${API}/remove/${device.id}/${sensors[idx].id}`, {
      method: 'DELETE',
    }).then(() => {
      setUnpairing(false);
    });
  };

  const removeSensor = (idx: number) => {
    let s = [...sensors];
    s.splice(idx, 1);
    setSensors(s);
  };

  function save() {
    const device = {
      id,
      name,
      latitude: parseFloat(latitude) || null,
      longitude: parseFloat(longitude) || null,
      sensors: sensors.map((s) => ({ id: s.id, name: s.name, type: s.type, comments: s.comments })),
    };

    return upsertDevice({
      variables: { device },
    });
  }

  return (
    <form className="bg-white shadow-md rounded px-8 pt-6 pb-8">
      <Input id="id" label="ID" value={id} onChange={setId} />
      <Input id="name" label="Name" value={name} onChange={setName} />
      <Input id="latitude" label="Latitude" value={latitude} onChange={setLatitude} />
      <Input id="longitude" label="Longitude" value={longitude} onChange={setLongitude} />
      <div className="flex justify-between my-5">
        <h3 className="inline">Sensors</h3>
        <Button onClick={addSensor}>Add</Button>
      </div>

      {sensors.map((value, index) => (
        <>
          <SensorEdit
            sensor={value}
            onChange={(prop, value) => onSensorChange(index, prop, value)}
            remove={(e) => {
              e.preventDefault();
              removeSensor(index);
            }}
            unpairing={unpairing}
            unpair={(e) => {
              e.preventDefault();
              unpair(index);
            }}
          />
        </>
      ))}

      <div className="flex items-center justify-between">
        <button
          className="bg-purple hover:bg-purple-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={() => save().then(() => history.push('/admin'))}
        >
          Save
        </button>
      </div>
    </form>
  );
}

export function DeviceEditPage() {
  const { id } = useParams();
  let device = undefined;
  const { loading, data } = useQuery(
    gql`
      query($id: String!) {
        device(id: $id) {
          id
          name
          latitude
          longitude
          editable
          sensors {
            id
            name
            comments
            type
          }
        }
      }
    `,
    { variables: { id } }
  );
  if (loading) return <Loading />;

  if (id === 'new') {
    device = { id: null, name: null, sensors: [], latitude: null, longitude: null, editable: true };
  } else {
    device = data?.device;
  }

  if (!device.editable) {
    return (
      <div>
        <Navbar />
        <Body>
          <div className="container mx-auto pl-2 py-5 sm:px-6 lg:py-10">
            <h1 className="text-5xl text-grey-darkest">Permission denied</h1>
          </div>
        </Body>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <Body>
        <div className="container mx-auto pl-2 py-5 sm:px-6 lg:py-10">
          <h1 className="text-5xl text-grey-darkest">Device Admin</h1>
        </div>
        <div className="container mx-auto pl-2 py-5 sm:px-6 lg:py-10">
          <DeviceEdit device={device} />
        </div>
      </Body>
    </div>
  );
}
