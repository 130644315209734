import React from 'react';
import { Link } from 'react-router-dom';
import './loading.css';
import './dropdown.css';
import { useAuth0 } from '../react-auth0-spa';

export function Body(props: any) {
  return <div className="bg-grey-lighter min-h-screen font-sans pb-24">{props.children}</div>;
}

export function Button(props: any) {
  return (
    <button
      className=" bg-transparent hover:bg-teal-dark font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
      {...props}
    >
      {props.children}
    </button>
  );
}

function NavbarDropdown(props: any) {
  return (
    <div className="dropdown">
      <h3 className="text-grey-dark no-underline hover:text-grey-darker">{props.title}</h3>
      <div className="dropdown-content">{props.children}</div>
    </div>
  );
}

export function Navbar() {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <nav className="container flex items-center justify-between mx-auto font-sans h-24">
      <Link to="/" className="no-underline px-4">
        <h1 className="text-grey-darkest w-full pl-4">
          <span className="font-bold">OpenAurae </span>
          <span className="font-thin hidden sm:inline">Dashboard</span>
        </h1>
      </Link>
      <Link to="/admin" className="no-underline px-4">
        <h3 className="text-grey-dark no-underline hover:text-grey-darker">Admin</h3>
      </Link>
      <NavbarDropdown title="Projects">
        <Link to="/building658" className="no-underline px-4">
          <h3 className="text-grey-dark no-underline hover:text-grey-darker">Building 658</h3>
        </Link>
        <Link to="/building87" className="no-underline px-4">
          <h3 className="text-grey-dark no-underline hover:text-grey-darker">Building 87</h3>
        </Link>
        <Link to="/monash" className="no-underline px-4">
          <h3 className="text-grey-dark no-underline hover:text-grey-darker">Monash Campus</h3>
        </Link>
      </NavbarDropdown>
      {isAuthenticated ? (
        <a
          className="no-underline px-4"
          onClick={() => logout({ returnTo: 'https://app.openaurae.org/' })}
        >
          <h3 className="text-grey-dark no-underline hover:text-grey-darker">Logout</h3>
        </a>
      ) : (
        <Link to="/login" className="no-underline px-4">
          <h3 className="text-grey-dark no-underline hover:text-grey-darker">Login</h3>
        </Link>
      )}
    </nav>
  );
}

export function Card(props: any) {
  let className = 'rounded bg-white text-grey-darkest shadow-lg my-8';
  if (props.padding !== false) {
    className += ' p-4';
  }
  return (
    <div className="container p-4 mx-auto ">
      <div className={className} {...props}>
        {props.children}
      </div>
    </div>
  );
}

export function Loading() {
  return (
    <>
      <div className="loading">
        <div />
        <div />
        <div />
        <div />
      </div>
    </>
  );
}

export function LoadingCover() {
  return (
    <div className="w-screen h-screen">
      <div className="m-auto">
        <Loading />
      </div>
    </div>
  );
}
