import React, { useState } from 'react';
import ReactMapboxGl, { Source, Layer, Feature, Popup } from 'react-mapbox-gl';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Loading } from '../Layout';
import MapDevice from './MapDevice';

const token =
  'pk.eyJ1IjoibW9uYXNoYXVyYWUiLCJhIjoiY2pyMGJqbzV2MDk3dTQ0bndqaHA4d3hzeSJ9.TDvqYvsmY1DHhE8N8_UbFg';

const ReactMap = ReactMapboxGl({ accessToken: token });

interface MapProps {
  mapStyle: string;
  center: [number, number];
  zoom: number;
  filterFn?: (device: any) => Boolean;
  selectDevice: (device: MapDevice) => void;
}

const DEVICES = gql`
  {
    devices {
      id
      name
      latitude
      longitude
      lastReading(readingTypes: [pms5003st, ptqs1005, zigbee_temp]) {
        time
        temperature
        pm25
      }
    }
  }
`;

interface PopupObj {
  coordinates: [number, number];
  properties: any;
}

export default function Map(props: MapProps) {
  const { loading, error, data } = useQuery(DEVICES, {
    pollInterval: 10_000,
  });

  const [viewport, setViewport] = useState({
    containerStyle: { width: '100%', height: '100.5%' },
    style: props.mapStyle,
  });
  const [zoom, setZoom] = useState([8] as [number]);
  const [center, setCenter] = useState([145.134424, -37.909365] as [number, number]);

  const [popup, setPopup] = useState<PopupObj | null>(null);

  if (loading) return <Loading />;

  const devices: any[] = data?.devices?.filter((d: any) => d.lastReading !== null);
  if (!devices) return <Loading />;

  const onMouseEnter = (e: any) =>
    setPopup({
      coordinates: e.lngLat,
      properties: JSON.parse(e.feature.properties.device),
    });
  const onMouseLeave = (e: any) => setPopup(null);

  const onClick = (e: any) => props.selectDevice(JSON.parse(e.feature.properties.device));

  return (
    <ReactMap
      {...viewport}
      zoom={zoom}
      center={center}
      onZoomEnd={(m, evt) => setZoom([m.getZoom()])}
      onMoveEnd={(m, evt) => setCenter([m.getCenter().lng, m.getCenter().lat])}
    >
      <Layer type="circle" id="devices" paint={{ 'circle-color': '#516b91', 'circle-radius': 8 }}>
        {devices.map((device) => (
          <Feature
            key={device.id}
            coordinates={[device.longitude, device.latitude]}
            properties={{ device }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
          />
        ))}
      </Layer>
      {(popup && (
        <Popup coordinates={popup.coordinates}>
          <p>
            <b>{popup.properties.name}</b>
          </p>
          <p>{popup.properties.lastReading.time}</p>
          <p>Temperature: {popup.properties.lastReading.temperature}</p>
        </Popup>
      )) || <></>}
    </ReactMap>
  );
}
