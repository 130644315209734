import React, { Component, Suspense, useState, useEffect } from 'react';
import { Body, Navbar, Card, Button, Loading } from '../Layout';
import DeviceList from './DeviceList';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';
import jwt_decode from 'jwt-decode';

export default function Admin() {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null as string | null);

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    getTokenSilently()
      .then((token: string) => setToken(token))
      .then(() => setLoading(false));
  });

  if (loading) return <Loading />;
  if (!token) return <Loading />;

  const decoded: any = jwt_decode(token);
  const { permissions } = decoded;
  console.log(permissions);
  let admin = permissions?.includes('admin');
  return (
    <div>
      <Navbar />
      <Body>
        <div className="container mx-auto pl-2 py-5 sm:px-6 lg:py-10 flex justify-between">
          <h1 className="text-5xl text-grey-darkest">Device Admin</h1>
          {admin && (
            <Button>
              <Link to="/admin/device/new"> Add Device</Link>
            </Button>
          )}
        </div>
        <div className="container mx-auto pl-2 py-5 sm:px-6 lg:py-10">
          <DeviceList admin={admin} />
        </div>
      </Body>
    </div>
  );
}
