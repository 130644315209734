import React, { Component, Suspense, useState } from 'react';
import './App.css';
import { Body, Navbar, Card, LoadingCover } from './Layout';
import Map from './Map/Map';
import MapDevice from './Map/MapDevice';

const SelectedDevice = React.lazy(async () => await import('./Map/SelectedDevice'));

export default function Home() {
  const [device, setDevice] = useState<MapDevice | null>(null);
  return (
    <div>
      <Navbar />
      <Body>
        <div className="container mx-auto pl-2 py-5 sm:px-6 lg:py-10">
          <h1 className="text-5xl text-grey-darkest">Live Air Quality</h1>
        </div>
        <Card padding={false} style={{ height: '60vh' }}>
          <Map
            mapStyle="mapbox://styles/mapbox/light-v9"
            center={[145.134424, -37.909365]}
            zoom={8}
            selectDevice={setDevice}
          />
        </Card>
        {device && (
          <Suspense fallback={<LoadingCover />}>
            <Card>
              <SelectedDevice device={device} />
            </Card>
          </Suspense>
        )}
      </Body>
    </div>
  );
}
