import React, { Component, Suspense, useState, useEffect } from 'react';
import { Body, Navbar, Card, LoadingCover, Loading, Button } from '../Layout';
import { DateTime } from 'luxon';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import '../index.css';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';
import jwtDecode from 'jwt-decode';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

const dtFormat = (s: string) =>
  DateTime.fromISO(s, { zone: 'utc' })
    .toLocal()
    .toFormat('HH:mm:s dd/MM/y');

const GET_DEVICES = gql`
  query {
    devices {
      id
      name
      latitude
      longitude
      last_record
      editable
      sensors {
        id
        name
        type
        comments
        last_record
      }
    }
  }
`;

const DELETE_DEVICE = gql`
  mutation($id: String!) {
    deleteDevice(id: $id) {
      id
    }
  }
`;

const API = process.env.REACT_APP_API_URL;

function DeviceDetail({ device, admin }: { device: any; admin: boolean }) {
  let last_reading = device.last_record
    ? DateTime.fromISO(device.last_record, { zone: 'utc' })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    : 'None';

  const [deleteDevice, { data }] = useMutation(DELETE_DEVICE);

  const [{ startDate, endDate }, setDates] = useState({ startDate: null, endDate: null } as {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  });
  const [focused, setFocused] = useState(null as any);

  const deleteD = () => {
    const confirm = window.confirm('Are you sure you want to delete this device?');
    if (confirm) {
      deleteDevice({ variables: { id: device.id }, refetchQueries: [{ query: GET_DEVICES }] });
    }
  };

  let downloadURL = `${API}/download/${device.id}`;
  if (startDate && endDate)
    downloadURL += `?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;

  return (
    <>
      <div className="font-mono ml-10 my-4">
        <p>Name: {device.name}</p>
        <p>ID: {device.id}</p>
        <p>Latitude: {device.latitude}</p>
        <p>Longitude: {device.longitude}</p>
        <p>Last Reading: {last_reading}</p>
        <br />
        <p className="font-bold">Sensors:</p>
        {device.sensors
          .sort((a: any, b: any) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name))
          .map((s: any) => (
            <>
              <br />
              <p>ID: {s.id}</p>
              <p>Type: {s.type}</p>
              <p>Name: {s.name}</p>
              <p>Comments: {s.Comments}</p>
              <p>Last Reading: {s.last_record || 'None'}</p>
            </>
          ))}
      </div>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDatesChange={(d) => setDates(d)}
        startDateId="start_date_download"
        endDateId="end_date_download"
        focusedInput={focused}
        onFocusChange={(f) => setFocused(f)}
        minDate={moment().subtract({months: 4})}
        isOutsideRange={() => false}
      />
      <Button disabled={!!(startDate && endDate)}>
        <a href={downloadURL} target="_blank">
          Download Data
        </a>
      </Button>
      {(admin || device.editable) && <Button onClick={() => deleteD()}>Remove</Button>}
    </>
  );
}

function DevicePreview({
  device,
  selected,
  toggleSelected,
  admin,
}: {
  device: any;
  selected: boolean;
  toggleSelected: () => void;
  admin: boolean;
}) {
  return (
    <div className="border bg-white text-grey-darkest px-4 py-3 rounded relative">
      <div className="my-4" role="alert">
        {(admin || device.editable) && (
          <div className="inline-block mr-10">
            <Link to={`/admin/device/${device.id}`}>
              <Button>Edit</Button>
            </Link>
          </div>
        )}
        <strong className="font-bold">{device.name}</strong>
        <span className="block sm:inline font-mono"> {device.id}</span>
        <span className="block sm:inline font-mono">
          {device.last_record && ` @ ${dtFormat(device.last_record)}`}
        </span>
        <button
          className="absolute px-4 py-3"
          style={{ top: 0, bottom: 0, right: 0 }}
          onClick={toggleSelected}
        >
          <svg
            className="fill-current opacity-75 h-10 w-10"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            {!selected && (
              <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
            )}
            {selected && (
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            )}
          </svg>
        </button>
      </div>
      {selected && (
        <div>
          <DeviceDetail device={device} admin={admin} />
        </div>
      )}
    </div>
  );
}

export default function DeviceList({ admin }: { admin: boolean }) {
  const [selected, setSelected] = useState(null);
  const { loading, error, data } = useQuery(GET_DEVICES);
  if (loading) return <Loading />;
  if (error) return <div>{error.message}</div>;

  let devices: any[] = data.devices;

  devices = devices.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      {devices.map((d: any) => (
        <DevicePreview
          admin={admin}
          device={d}
          selected={d.id == selected}
          toggleSelected={() => setSelected(d.id == selected ? null : d.id)}
        />
      ))}
    </div>
  );
}
