import React, { Component, Suspense, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { LoadingCover, Loading } from './Layout';
import Home from './Home';
import Admin from './Admin/Admin';
import { DeviceEditPage } from './Admin/DeviceEdit';
import PrivateRoute from './PrivateRoute';
import { useAuth0 } from './react-auth0-spa';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';

function LoadableAdmin(props: any) {
  return (
    <Suspense fallback={<LoadingCover />}>
      <Admin {...props} />
    </Suspense>
  );
}

function LoadableDeviceEdit(props: any) {
  return (
    <Suspense fallback={<LoadingCover />}>
      <DeviceEditPage {...props} />
    </Suspense>
  );
}

function App() {
  const { loading, getTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  const [client, setClient]: [any, any] = useState(null);
  useEffect(() => {
    console.log(loading, isAuthenticated)
    if (loading) return;
    if (!isAuthenticated) return loginWithRedirect({ appState: { path: '/' } });
    // Instantiate required constructor fields
    const API_URL = process.env.REACT_APP_API_URL + '/graphql';
    getTokenSilently().then((token: any) => {
      const cache = new InMemoryCache();
      const link = new HttpLink({
        uri: API_URL,
        fetch: fetch,
        headers: { Authorization: 'Bearer ' + token },
      });
      const c = new ApolloClient({
        cache,
        link,
      } as any);
      setClient(c);
    });
  }, [isAuthenticated, loading]);
  if (loading) return <Loading />;
  if (!client) return <Loading />;

  return (
    <ApolloProvider client={client}>
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/admin" component={LoadableAdmin} />
        <Route exact path="/admin/device/:id" component={LoadableDeviceEdit} />
      </Router>
    </ApolloProvider>
  );
}

export default App;
